<script setup>
import { useDisplay } from "vuetify";

const { mdAndUp, lgAndUp, smAndUp } = useDisplay();

</script>

<template>
	<v-row  class="mt-5">

		<v-col cols="12" md="6">
			<div
				class=" min-h-60  my-auto backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] p-4 md:p-6">


				<div class="flex">
					<div class="flex flex-col mt-5 mt-md-10">
						<div class="flex">
							<h1 class="   md:text-4xl text-4xl font-bold mb-10" v-html="__t('aiPowerTitle')"></h1>
							<img preload alt="3kode" src="/assets/home/robot2.svg" v-if="!lgAndUp"	class="bottom-0 left-0  transform transition-all w-1/2  duration-700 opacity-90 "></img>
						</div>
						<p class="mt-1  leading-9">
							{{ __t("aiPower") }}
						</p>
					</div>

					<img preload alt="3kode" src="/assets/home/robot2.svg" v-if="lgAndUp"	
						class="  bottom-0 left-0  transform transition-all w-1/3  duration-700 opacity-90 "></img>
				</div>

			</div>
			<div
				class=" min-h-60  my-auto backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] mt-5 p-4 md:p-6">
				<div class="flex">
					<div class="flex flex-col mt-5 mt-md-10">
						<div class="flex">
							<h1 class="   md:text-4xl text-4xl font-bold mb-10" v-html="__t('makeProfitTitle')"></h1>
							<img preload alt="3kode" src="/assets/home/mon2.svg" v-if="!lgAndUp"	class="bottom-0 left-0  transform transition-all w-1/2  duration-700 opacity-90 "></img>
						</div>
						<p class="mt-1  leading-9">
							{{ __t("makeProfit") }}
						</p>
					</div>

					<img preload alt="3kode" src="/assets/home/mon2.svg" v-if="lgAndUp"
						class="  bottom-0 left-0  transform transition-all w-1/3  duration-700 opacity-90 "></img>
				</div>

			</div>
		</v-col>
		<v-col cols="12" md="6">
			<div class=" min-h-60 h-full  my-auto backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] 
				relative p-4 md:p-6 flex flex-col items-start justify-center">
				<img preload alt="3kode" src="/assets/home/text.png"
					class="absolute top-0 rtl:left-0 ltr:right-0 transform transition-all w-1/2 md:w-1/3 lg:w-1/2 duration-700 opacity-25 "></img>
				<div class="flex flex-col mt-5 mt-md-10">
					<h1 class="   md:text-6xl text-4xl font-bold mb-10" v-html="__t('aboutUsTitle')"></h1>
				</div>
				<p class="mt-1  leading-9">
					{{ __t("aboutUs") }}
				</p>
				<img preload alt="3kode" src="/assets/3kode.svg" v-if="mdAndUp"
					class=" absolute bottom-10  rtl:left-10 ltr:right-10 transform transition-all w-1/2  duration-700 opacity-50 "></img>
			</div>

		</v-col>
		<!-- <v-col cols="12" md="6">
			<div class="flex flex-col">
				<v-img :width="274" :height="67" :src="__t('Images.aboutUs')" class="my-5" alt="About 3kode" />
			</div>
			<p>
				{{ __t("aboutUs") }}
			</p>
		</v-col>
		<v-col cols="12" md="6" class="flex flex-col space-y-5">
			<div>
				<v-img alt="our vision" :width="274" :height="67" :src="__t('Images.ourVision')" class="my-5" />
				<p class="text-start w-full">
					{{ __t("vision") }}
				</p>
			</div>
			<div>
				<v-img alt="Our Mission" :width="274" :height="67" :src="__t('Images.ourMission')" class="my-5" />
				<p class="text-start w-full">
					{{ __t("goal") }}
				</p>
			</div>
		</v-col> -->
	</v-row>
</template>
